import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';

import { routes } from './routes';
import StoreData from './store';
import WalletMainApp from './components/WalletMainApp.vue';
import { initialize } from '@/middleware/middleware'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import BootstrapVue from 'bootstrap-vue'
import IdleVue from 'idle-vue'

//swal
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueI18n from 'vue-i18n'
const eventsHub = new Vue()

Vue.use(VueSweetalert2);

Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    idleTime: 1000 * 60 * 3
})


//warning for user

console.log("%cDANGER ZONE\n-----------\nDO NOT PASTE SCRIPTS HERE.", "font-size:x-large;color:#FF900A;")

console.log("%cScripts executed here might put your account in danger", "color:#FF900A")

Vue.use(BootstrapVue)
Vue.use(VueI18n)

Vue.use(VueRouter);
Vue.use(Vuex);


const store = new Vuex.Store(StoreData);

const router = new VueRouter({
    routes,
    mode: "history",
    scrollBehavior(_) {
    return { x: 0, y: 0 }
}
});

initialize(store, router);
var i18n = new VueI18n({
    locale: localStorage.getItem("locale") == "np" ? "np" : "en",
})
new Vue({
    render: h => h(WalletMainApp),
    router,
    store,
    i18n,
    onIdle() {
        const logoutWhenInactive = localStorage.getItem("logoutWhenInactive") ? localStorage.getItem("logoutWhenInActive") : true

        if (sessionStorage.getItem("_token") && logoutWhenInactive) {
            sessionStorage.removeItem("_token")
            this.$swal({
                text: "As you were away, we logged you out.",
                title: "Hey there.",
                type: 'info',
                allowOutsideClick: false
            }).then(r => {
                if (r.value) {
                    location.reload();
                }
            })
        }
    }
}).$mount("#app");