<i18n>
  {
    "en": {
      "wallet": "Wallet",
      "settings": "Settings"
    },
    "np": {
       "wallet": "वालेट",
       "settings": "सेटिङ"
     }
  }
</i18n>

<template>
  <div>
    <div class="account-header">
      <div class="container">
        <div class="profile-pic">
          <img
            :alt="user.name"
            :src="user.imageURL"
            style="cursor: pointer"
            class="img-profile"
          />
          <audio style="display: none" id="audio">
            <source src="@/assets/bank_withdraw.wav" />
          </audio>

          <!--
						<input
							style="display: none"
							type="file"
							@change="onProfilePicSelected"
							ref="fileInput"
							accept="image/*"
						/>
						<div class="no-image">
							<i class="fa fa-camera"></i>
						</div>
						<div class="overlay-img">
							<div class="overlay-text">
								<i class="fa fa-pencil-alt"></i>
							</div>
          </div>-->
        </div>
        <div class="account-menu">
          <h5 class="pt-3 font-weight-bold">{{ user.name }}</h5>
          <ul class="nav">
            <li class="nav-item">
              <router-link to="/" class="nav-link">{{$t("wallet")}}</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/settings" class="nav-link">{{ $t("settings") }}</router-link>
            </li>
            <!--  <li class="nav-item">
								<router-link to="/shop/myorder" class="nav-link">My Orders</router-link>
							</li>
							<li class="nav-item">
								<router-link to="/shop/wishlist" class="nav-link">Wishlist</router-link>
            </li>-->
            <!-- <li class="nav-item">
								<router-link to="/user/myprofile" class="nav-link">My Profile</router-link>
            </li>-->
            <li class="nav-item">
              <a href="#" @click.prevent="logMeOut" class="nav-link">
                <i class="fas fa-sign-out-alt text-danger"></i>
              </a>
            </li>
          </ul>
        </div>
        <div class="balance-box font-weight-bold pt-4 text-right">
          <div>
            <i class="fas fa-wallet" title="Wallet"></i>
            {{ balanceOfUser }}
          </div>
          <div>
            <i class="fas fa-gift" title="Reward"></i>
            {{ user.reward }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>
<script>
import { TweenLite, Power0 } from "gsap";
export default {
  name: "app-header",
  data() {
    return {
      selectedProfilePic: null,
      animatedBalance: 0,
    };
  },

  created() {
    this.$store.dispatch("UPDATE_BALANCE");
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    balanceOfUser() {
      return this.animatedBalance.toFixed(2);
    },
  },
  watch: {
    user(newValue) {
      if (this.animatedBalance == 0) this.animatedBalance = newValue.balance;
      else {
        const playAudio = localStorage.getItem("playAudio") || false;
        let audio = document.querySelector("#audio");
        audio.volume = 0.05;
        TweenLite.to(this.$data, 2, {
          animatedBalance: newValue.balance,
          ease: Power0.easeIn,
          onComplete: () => {
            audio.pause();
            audio.currentTime = 0;
          },
        });
        if (playAudio) {
          audio.play();
        }
      }
    },
  },
  methods: {
    logMeOut() {
      sessionStorage.removeItem("_token");
      location.reload();
    },
  },
};
</script>
