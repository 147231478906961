<template lang='pug'>
	#container
		.page-account-detail#body
			div
				title-header
				.main-wrapper
					.account-box
						wallet-header( v-if="profile")
						.my-account-content( :class="{ active: profile }" style="min-height: 400px;")
							router-view
		wallet-footer
</template>

<script>
import WalletHeader from "./parts/Header";
import WalletFooter from "./parts/Footer";
import TitleHeader from "./parts/TitleHeader";

export default {
  name: "app",
  components: { WalletHeader, WalletFooter, TitleHeader },
  computed: {
    profile() {
      return this.$store.state.loggedIn;
    }
  }
};
</script>
