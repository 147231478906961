import { prepareHeaders } from "@/helpers/general";
import axios from "@/axios";

export function initialize(store, router) {
  router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const vendorPayment = to.matched.some((record) => record.meta.vendorPayment);

    if (vendorPayment) {
        to.query.intent && sessionStorage.setItem("intent", to.query.intent);
    }
    if (sessionStorage.getItem("_token")) {
      store.state.loggedIn = true;
      prepareHeaders(sessionStorage.getItem("_token"), axios);
    }
    const currentUser = store.state.loggedIn;
    if (requiresAuth && !currentUser) {
      next("/login");
    } else if (!requiresAuth && currentUser) {
      next("/");
    } else {
      next();
    }
  });

  axios.interceptors.response.use(null, (error) => {
    if (error.response && [401, 403].includes(error.response.status)) {
      store.state.loggedIn = false;
      sessionStorage.removeItem("_token");
      router.push("/login");
    }

    return Promise.reject(error);
  });

  if (store.getters.currentUser) {
    //setAuthorization(store.getters.currentUser.token);
  }
}
