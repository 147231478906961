<i18n>
  {
    "en": {
      "companyTitle": "Kuraakani Pay Pvt. Ltd.",
      "companyInfo": "An institution licensed by Nepal Rastra Bank as Payment Service Provider",
      "policyInfo": "Policy Info",
      "address": "2-Jhamsikhel, Lalitpur, Nepal",
      "poBox": "P.O. Box no. 516",
      "tollFreeNumber": "Toll Free Number",
      "grievanceOfficer": "GRIEVANCE OFFICER",
      "manishShrestha": "Suman Shrestha",
      "nrbGunasoPortal": "NRB Grievance Portal",
      "gunasoForm": "Grievance Form",
      "+9779748265411": "+977-9748265411",
      "16600155332": "16600155332",
      "+977-1-5916973": "+977-1-5916973",
      "Charges & Payments": "Charges & Payments",
      "Partner with us": "Partner with us",
      "Start Accepting Payments": "Start Accepting Payments",
      "Integrate/View our API documentation": "Integrate/View our API documentation",
      "Terms & Conditions": "Terms & Conditions",
      "Privacy Policy": "Privacy Policy",
      "CONNECT TO US": "CONNECT TO US",
      "Download App": "Download App"
    },
    "np": {
      "companyTitle": "कुराकानी पे प्रा. ली",
      "companyInfo": "नेपाल राष्ट्र बैंकबाट भुक्तानी सेवा प्रदायकको रूपमा इजाजतपत्र प्राप्त संस्था",
      "policyInfo": "नीति जानकारी",
      "address": "२-झम्सिखेल, ललितपुर, नेपाल",
      "poBox": "P.O बक्स नं. ५१६",
      "tollFreeNumber": "टोल फ्री नम्बर",
      "grievanceOfficer": "गुनासो अधिकारी",
      "manishShrestha": "मनिष श्रेष्ठ",
      "nrbGunasoPortal": "NRB गुनासो पोर्टल",
      "+9779748265411": "+९७७-९७४८२६५४११",
      "16600155332": "१६६००१५५३३२",
      "+977-1-5916973": "+९७७-१-५९१६९७३",
      "Charges & Payments": "शुल्क र भुक्तानीहरू",
      "Partner with us": "साझेदार बन्नुहोस्",
      "Start Accepting Payments": "भुक्तानीहरू स्वीकार गर्न सुरु गर्नुहोस्",
      "Integrate/View our API documentation": "हाम्रो API कागजातहरू हेर्नुहोस्",
      "Terms & Conditions": "नियम तथा सर्त",
      "Privacy Policy": "गोपनीयता नीति",
      "CONNECT TO US": "हामीसँग जडान गर्नुहोस्",
      "Download App": "एप डाउनलोड गर्नुहोस्",
      "gunasoForm": "गुनासो फारम"
    }
  }
</i18n>
<template>
  <div class="footer-content" id="footer">
    <div class="footer">
      <div class="footer-widget-wrapper">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-4 col-md-3 col-lg-3">
              <div class="widget-box">
                <h5>{{ $t("companyTitle") }}</h5>
                <h6 style="color: white">{{ $t("companyInfo") }}</h6>
                <ul>
                  <li>
                    <a href="#">{{ $t("address") }}</a>
                  </li>
                  <li>
                    <a href="#">{{ $t("poBox") }}</a>
                  </li>
                  <li>
                    <a href="tel:+977-1-5916973">{{ $t("+977-1-5916973") }}</a>
                  </li>
                  <li>
                    <a href="tel:16600155332"
                      >{{ $t("tollFreeNumber") }}: {{ $t("16600155332") }}</a
                    >
                  </li>
                  <li>
                    <a href="tel:18105000072"
                      >{{ $t("tollFreeNumber") }}: {{ $t("18105000072") }}</a
                    >
                  </li>

                  <li>
                    <a href="mailto:nimbuzzpay@gmail.com">nimbuzzpay@gmail.com</a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a href="#">{{ $t("grievanceOfficer") }}</a>
                  </li>

                  <li>
                    <a href="#">{{ $t("manishShrestha") }}</a>
                  </li>
                  <li>
                    <a href="mailto:customerservice.nimbuzzpay@gmail.com"
                      >customerservice.nimbuzzpay@gmail.com</a
                    >
                  </li>
                  <li>
                    <a href="tel:+9779748265411">{{ $t("+9779748265411") }}</a>
                  </li>
                  <li>
                    <a href="https://gunaso.nrb.org.np/" target="_blank">{{
                      $t("nrbGunasoPortal")
                    }}</a>
                  </li>
                  <li>
                    <a href="/grievance-form" target="_blank">{{ $t("gunasoForm") }}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-sm-4 col-md-3 col-lg-3">
              <div class="widget-box">
                <h5>{{ $t("policyInfo") }}</h5>
                <ul>
                  <li>
                    <a href="/charges" target="_blank">{{ $t("Charges & Payments") }}</a>
                  </li>
                  <li>
                    <a href="/tos" target="_blank">{{ $t("Terms & Conditions") }}</a>
                  </li>
                  <li>
                    <a href="/privacy" target="_blank">{{ $t("Privacy Policy") }}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3">
              <div class="widget-box">
                <h5>{{ $t("Partner with us") }}</h5>
                <ul class="social-menu">
                  <li>
                    <a href="/vendor-apply">
                      {{ $t("Start Accepting Payments") }} <br />
                      {{ $t("Integrate/View our API documentation") }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3">
              <div class="widget-box">
                <h5>{{ $t("CONNECT TO US") }}</h5>
                <ul class="social-menu">
                  <li>
                    <a
                      href="https://www.facebook.com/Nimbuzz"
                      target="_blank"
                      class="icon-social icon-facebook"
                      data-toggle="tooltip"
                      title="Facebook"
                    >
                      <i class="fab fa-facebook-f"></i>
                    </a>
                    <a
                      href="https://www.twitter.com/nimbuzz"
                      target="_blank"
                      class="icon-social icon-twitter"
                      data-toggle="tooltip"
                      title="Twitter"
                    >
                      <i class="fab fa-twitter"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/nimbuzz.kuraakani"
                      target="_blank"
                      class="icon-social icon-instagram"
                      data-toggle="tooltip"
                      title="Instagram"
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/nimbuzz/"
                      target="_blank"
                      class="icon-social icon-linkedin"
                      data-toggle="tooltip"
                      title="Linkedin"
                    >
                      <i class="fab fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="row my-5">
                <div class="col-12">
                  <h5>{{ $t("Download App") }}</h5>
                  <div>
                    <div class="row">
                      <div class="col-12">
                        <a
                          href="https://apps.apple.com/us/app/nimbuzz/id1459552105"
                          class="btn btn-primary mr-3"
                          target="_blank"
                          ><i class="fab fa-apple"></i> App Store</a
                        >
                        <a
                          href="https://play.google.com/store/apps/details?id=com.kuraakani.nimbuzz"
                          class="btn btn-danger"
                          target="_blank"
                          ><i class="fab fa-google-play"></i> Play Store</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <p>
          &copy; {{ new Date().getFullYear() }} Kuraakani Pay Pvt. Ltd. All rights
          reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-footer",
  methods: {
    logout() {
      this.$store.commit("logoutFunc");
      this.$router.push("/wallet/login");
    },
  },
  computed: {
    baseUrl() {
      return this.$store.getters.baseUrl;
    },
    welcomemessage() {
      return this.$store.getters.introMsg;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
};
</script>
