import { lazyReactInVue, lazyPureReactInVue } from "vuereact-combined";

export const routes = [
  {
    path: "/settings",
    component: () => import("@/pages/actions/Settings"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    component: () => import("@/pages/actions/Login"),
  },
  {
    path: "/register",
    component: () => import("@/pages/actions/Register"),
  },
  {
    path: "/recover",
    component: () => import("@/pages/actions/Recover"),
  },
  {
    path: "/tos",
    component: () => import("@/pages/actions/tos"),
  },
  {
    path: "/grievance-form",
    component: () => import("@/pages/actions/grievanceForm"),
  },
  {
    path: "/privacy",
    component: () => import("@/pages/actions/privacy"),
  },
  {
    path: "/change-password",
    component: () => import("@/pages/actions/ChangePassword"),
  },
  {
    path: "/set-pin",
    component: () => import("@/pages/actions/SetPin"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reset-pin",
    component: () => import("@/pages/actions/ResetPin"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/know-your-customer",
    component: () => import("@/pages/actions/KycForm"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/",
    component: () => import("@/pages/Home"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/services/:slug",
    component: () => import("@/pages/Services"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/load-fund",
    component: () => import("@/pages/actions/LoadFundEbanking"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/transfer-fund",
    component: () => import("@/pages/actions/TransferFund"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/withdraw-fund",
    component: () => import("@/pages/actions/WithdrawFund"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/statements",
    component: () => import("@/pages/actions/Statements"),
    meta: {
      requiresAuth: true,
    },
  },

  //utilities
  {
    path: "/utility-nea",
    component: () => import("@/pages/utilities/UtilityNEA"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/utility-nws",
    component: () => import("./pages/utilities/NepalWaterSupply"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/utility-upabhokta",
    component: () => import("./pages/utilities/Upabhokta"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/utility-maxtv",
    component: lazyReactInVue(() => import("@/pages/utilities/MaxTv")),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/utility-prabhuTvOTT",
    component: lazyReactInVue(() => import("@/pages/utilities/PrabhuTvOTT")),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/utility-prabhuTvSetUp",
    component: lazyReactInVue(() => import("@/pages/utilities/PrabhuTvSetUp")),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/utility-simtv",
    component: lazyReactInVue(() => import("@/pages/utilities/SimTv")),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/flight/search",
    component: () => import("./pages/flight/search"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/bus-sewa",
    component: () => import("./pages/bus/BusSewa"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/bus-sewa/tickets",
    component: () => import("./pages/bus/MyTickets"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/edx-courses",
    component: () => import("./pages/learn/EdxCourses"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/edx-courses/:id",
    component: () => import("./pages/learn/EdxCourseDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/utility-dishhome",
    component: () => import("./pages/utilities/DishHome"),
    meta: {
      requiresAuth: true,
    },
  },
  //CREDIT CARD
  {
    path: "/credit-card",
    component: lazyReactInVue(() => import("@/pages/utilities/CreditCard")),
    meta: {
      requiresAuth: true,
    },
  },
  //topup
  {
    path: "/topup",
    component: () => import("@/pages/topup/TopUp"),
    meta: {
      requiresAuth: true,
    },
  },
  //data pack
  {
    path: "/data-pack/:telecom",
    component: lazyReactInVue(() => import("@/pages/topup/DataPack")),
    meta: {
      requiresAuth: true,
    },
  },
  //health care
  {
    path: "/health-care",
    component: lazyReactInVue(() => import("./pages/healthCare/HealthCare")),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/health-care/:labId",
    component: lazyReactInVue(() =>
      import("./pages/healthCare/HealthCareDetail")
    ),
    meta: {
      requiresAuth: true,
    },
  },
  //Insurance
  {
    path: "/insurance-gic",
    component: lazyReactInVue(() => import("./pages/insurance/GICInsurance")),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/insurance-premier",
    component: lazyReactInVue(() =>
      import("./pages/insurance/PremierInsurance")
    ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/insurance-siddhartha",
    component: lazyReactInVue(() =>
      import("./pages/insurance/SiddharthaInsurance")
    ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/insurance-nlg",
    component: lazyReactInVue(() => import("./pages/insurance/NLGInsurance")),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/insurance-himalayan",
    component: lazyReactInVue(() =>
      import("./pages/insurance/HimalayanInsurance")
    ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/insurance-reliance",
    component: lazyReactInVue(() =>
      import("./pages/insurance/RelianceInsurance")
    ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/insurance-union",
    component: lazyReactInVue(() =>
      import("./pages/insurance/UnionLifeInsurance")
    ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/insurance-sagarmatha",
    component: lazyReactInVue(() =>
      import("./pages/insurance/SagarmathaInsurance")
    ),
    meta: {
      requiresAuth: true,
    },
  },
  //internet
  {
    path: "/internet-subisu",
    component: lazyReactInVue(() =>
      import("./pages/internet/InternetBillSubisu")
    ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/internet-worldlink",
    component: () => import("@/pages/internet/InternetBillWorldlink"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/internet-arrownet",
    component: lazyReactInVue(() =>
      import("@/pages/internet/InternetBillArrownet")
    ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/internet-lifenet",
    component: lazyReactInVue(() =>
      import("@/pages/internet/InternetBillLifenet")
    ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/internet-firstlink",
    component: lazyReactInVue(() =>
      import("@/pages/internet/InternetBillFirstlink")
    ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/internet-vianet",
    component: () => import("@/pages/internet/InternetBillVianet"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/internet-classictech",
    component: () => import("@/pages/internet/InternetBillClassic"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/internet-cosmicnet",
    component: lazyReactInVue(() =>
      import("@/pages/internet/InternetBillCosmicnet")
    ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/internet-broadlink",
    component: lazyReactInVue(() =>
      import("@/pages/internet/InternetBillBroadlink")
    ),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "*",
    component: lazyReactInVue(() =>
      import("@/pages/vendorPayment/IntentPayment")
    ),
  },

  // movie ticket
  {
    path: "/movies",
    component: lazyReactInVue(() => import("@/pages/moviesTicket/MoviesList")),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/movie-show-time/:movieId/:processId",
    component: lazyReactInVue(() =>
      import("@/pages/moviesTicket/MovieShowTime")
    ),
    meta: {
      requiresAuth: true,
    },
  },
  // cable car
  {
    path: "/cable_car/manakamanacablecar",
    component: lazyReactInVue(() =>
      import("@/pages/cableCar/ManakamanaCableCar")
    ),
    meta: {
      requiresAuth: true,
    },
  },
  //Vendor Intenet Payment
  {
    path: "/vendor/payment",
    component: lazyReactInVue(() =>
      import("@/pages/vendorPayment/IntentPayment")
    ),
    meta: {
      requiresAuth: true,
      vendorPayment: true,
    },
    props: (route) => ({ intent: route.query.intent }),
  },
  {
    path: "/charges",
    component: () => import("@/pages/actions/Charges"),
  },
  // Vendor registration for api.
  {
    path: "/vendor-apply",
    component: lazyReactInVue(() => import("@/pages/actions/vendorRegister")),
  },
  {
    path: "/esim-merchant",
    component: lazyReactInVue(() => import("@/pages/actions/esimMerchantRegister")),
  },
  // Vendor api docs.
  {
    path: "/vendor-api-docs",
    component: lazyReactInVue(() =>
      import("@/pages/vendorApiDocs/VendorApiDocs")
    ),
  },
  {
    path: "/about",
    component: () => import("@/pages/actions/Marketing")
  }
];
