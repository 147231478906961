import axios from "@/axios";
export default {
  state: {
    user: {},
    loggedIn: false,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = {
        ...state.user,
        ...user,
      };
    },
  },
  actions: {
    UPDATE_BALANCE({ commit }) {
      axios.post("api/v4/account/balance").then((r) => {
        const userDetails = JSON.parse(localStorage.getItem("user"));
        if (r.data) {
          let user = {
            balance: r.data.data.balance,
            reward: 0,
            name: userDetails.name,
            imageURL:
              process.env.VUE_APP_REMOTE_SERVER_1 +
              "/avatar/user/" +
              userDetails.imageURL,
            id: userDetails.uid,
          };
          commit("SET_USER", user);
        }
      });
    },
  },
};
