import shajs from 'sha.js'
import moment from 'moment'


// user authentication server.
export const REMOTE_SERVER_1 = process.env.VUE_APP_REMOTE_SERVER_1

//for files in wallet.
export const FILE_SERVER = process.env.VUE_APP_FILE_SERVER

//server for wallet api calls.
export const REMOTE_SERVER = process.env.VUE_APP_TESTING_SERVER
    //export const REMOTE_SERVER = process.env.LOCAL_SERVER

/**
 * SALT to generate hash for secret and passcode.
 *  */
export const SALT = process.env.VUE_APP_SALT

/**
 * APIKEY based on remote server @see REMOTE_SERVER
 */
export const APIKEY = process.env.VUE_APP_APIKEY


export function prepareHeaders(token, axios) {
    if (token != null) {
        axios.defaults.headers.common = {
            "access-token": token,
            "apikey": APIKEY
        }
    }
}

//generate secret token valid for 10 secs//
export function getSecret() {

    let tenSec = Math.floor(
        moment()
        .utc()
        .valueOf() /
        1000 /
        10
    );
    let key = SALT + tenSec;

    return shajs("sha256")
        .update(key)
        .digest("hex");

}
/** generate hash from salt  @see {@link SALT}
 *
 * @param {*} string as plain password
 */
export function makePasswordHash(string) {
    return shajs("sha256")
        .update(string + SALT)
        .digest("hex");
}

/**
 * Perform validation on phone number with available services.
 * @param{string} number Valid Phone number.
 * @param{*} remoteServices Available Services From Remote Server
 *
 * (For more info on demonimations. @see epay documentation)
 *
 * @returns remoteServices with denominations
 */
export function validateNumberWithService(number, remoteServices) {
    //@denominations available set of denomination values

    let denominations = {
            range_10_5000: {
                min: 20,
                max: 5000
            },
            fixed_100_5000: {
                allowed: ["100", "150", "200", "300", "500", "1000", "1500", "2000", "2500", "3000", "4000", "5000"]
            },
            fixed_20_1000: {
                allowed: ['20', '50', '100', '200', '500', '1000']
            }
        }
        //@services services with their predefined set of prefixes and denominations
        // @TODO NCELL_PRO and NCELL_PREPAID to NCELL
    let services = [{
            name: "NCELL",
            prefixes: ["980", "981", "982"],
            denomination: denominations.range_10_5000
        },
        {
            name: "NT_PREPAID_GSM",
            prefixes: ['984', '986'],
            denomination: denominations.range_10_5000
        },
        {
            name: "NT_PREPAID_CDMA",
            prefixes: ['974'],
            denomination: denominations.fixed_100_5000
        },
        {
            name: "NT_POSTPAID_GSM",
            prefixes: ['985'],
            denomination: denominations.fixed_100_5000
        },
        {
            name: "NT_POSTPAID_CDMA",
            prefixes: ['975'],
            denomination: denominations.fixed_100_5000
        },
        {
            name: "SMART_CELL",
            prefixes: ['961', '962', '988'],
            denomination: denominations.fixed_20_1000
        },
        {
            name: "NT_PSTN_LANDLINE",
            prefixes: ['0'],
            denomination: denominations.fixed_100_5000
        },
        {
            name: "NT_ADSL_VOLUMEBASE",
            prefixes: ['0'],
            denomination: denominations.fixed_100_5000
        },
        {
            name: "NT_ADSL_UNLIMITED",
            prefixes: ['0'],
            denomination: denominations.fixed_100_5000
        },
        {
            name: "NT_CDMA_FIXED_PHONE",
            prefixes: ['0'],
            denomination: denominations.fixed_100_5000
        },
        {
            name: "NT_WIMAX",
            prefixes: ['0'],
            denomination: denominations.fixed_100_5000
        }
    ]
    if (remoteServices) {
        let service = services.find(s => {
            let reg = new RegExp("^(" + s.prefixes.join("|") + ")");
            return reg.test(number);
        })

        /* services = services.filter(s => {
            let reg = new RegExp("^(" + s.prefixes.join("|") + ")");
            return reg.test(number);
        }) */
        let remoteService = {}
        if (service) {
            remoteServices = remoteServices.map(rs => {
                return {
                    ...services.find(sr => {
                        return sr.name === rs.shortName
                    }),
                    ...rs
                }
            })
            remoteServices = remoteServices.filter(s => {
                if (s.prefixes) {
                    let reg = new RegExp("^(" + s.prefixes.join("|") + ")");
                    return reg.test(number);
                }
            })
            remoteService = remoteServices.find(sr => {
                return service.name === sr.shortName
            })
            return {
                service: remoteService,
                services: remoteServices
            }
        }
    }
    return null
}



/**
 * Validate Header Token And API Key performing simple GET request.
 * @param {Object} axios
 * @returns true if valid.
 */
export function verifyTokenValidity(axios) {
    if (axios && axios.defaults.headers.common)
        axios.get(`${REMOTE_SERVER_1}users`).then(r => {
            return r.data && r.data.user
        })
    return false
}




/**
 * Make data from server more readable..
 * @param{array} officeCode  raw array sent from server w/o modification.
 **/
export function neaDataSimplify(officeCode) {
    let properFormat = {}
    officeCode.forEach(code => {
        properFormat[code.input[0].value] = code.input[1].value
    })
    officeCode.sort
    return properFormat

}



/**
 * Make data from server more readable..
 * @param{array} officeCode  raw array sent from server w/o modification.
 **/
export function nwsDataSimplify(officeCode) {
    let properFormat = {}
    officeCode.forEach(code => {
        properFormat[code.input[1].value] = code.input[2].value
    })
    return properFormat

}