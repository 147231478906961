<template>
  <header>
    <div class="d-flex flex-row justify-content-between page-account-detail navbar">
      <nav class="navbar navbar-expand-lg navbar-light navbar-class">
        <a class="navbar-brand" href="/">
          <img src="/assets/nimbuzz.png" height="60" class="1" />
        </a>
      </nav>
      <b-dropdown dropleft id="dropdown-1" size="sm" variant="outline-light" style="background-color: transparent;" class="m-md-2 mr-2"
        toggle-class="text-decoration-none box-shadow: none !important;" no-caret>
        <template #button-content>
          <span class="fa fa-language"></span><span class="sr-only">Search</span>
        </template>
        
        <b-dropdown-item :active="checkLocale('en')" @click="changeLocale('en')">English</b-dropdown-item>
        <b-dropdown-item :active="checkLocale('np')" @click="changeLocale('np')">नेपाली</b-dropdown-item>
      </b-dropdown>
    </div>

  </header>
</template>
<script>
export default {
  name: "TitleHeader",
  methods: {
    checkLocale(locale) {
      return this.$i18n.locale == locale
    },
    changeLocale(locale) {
      console.log("changing locale", locale)
      this.$root.$i18n.locale = locale
      localStorage.setItem("locale", locale)
    }
  }
};
</script>
